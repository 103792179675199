$(document).ready(function() {

    function addLandscapeClass(){
        var win1 = $(window);
        var screenOrientation = ($(window).width() > $(window).height())? 90 : 0;

        if(screenOrientation == 90){
            $('.center-sun, .home-callout').addClass('landscape');
            $( document ).scrollTop(0);
        }
        else{
            if($(window).height() < 1100){
                $('.content').addClass('max1100');
            }
            else{
                $('.content').removeClass('max1100');
            }
            $('.center-sun, .home-callout').removeClass('landscape');
        }
        $('body').height(win1[0].innerHeight);
    }
    addLandscapeClass();
    $(window).bind("resize", addLandscapeClass );


    if (!window.lkApi) {
        lkApi = window.lkApi = {
            URLS: {
                registerUrl: '/api/user-details/',
                accountSetupUrl: '/api/activate-user',
                accountUpdateUrl: '/api/edit-user',
                updateSessionStatus: '/api/session/change-status',
                loginUrl: '/api/login-user',
                prideJournalDataUrl: '/api/pride-journal',
                generateCertificateUrl: '/api/sessions/generate-certificate',
                resetPasswordUrl: '/api/forgot-password',
                submitHelpUrl: '/api/send-help',
                submitScholarshipUrl: '/api/wufoo-form'
            },
            REDIRECT_URLS: {
                jr: 'http://www.mtishows.com/show_detail.asp?showid=000459',
                kids: 'http://www.mtishows.com/show_detail.asp?showid=000460',
                timeout: 4000
            },
            PROMO_CODE: '000000',
            landingPageSettings: {
                enlargeVideoHeightWith: 120
            },
            ACCOUNT_DETAILS: {},
            settings: {
                maxVisibleSessionRows: 3,
                carouselVertical: true,
                carouselSpeed: 800
            },
            SESSION_BOX_SELECTED: null,
            SESSION_ID: null,
            showShadowsAfterThisWidth: 1920,
            scrollSettings: {
                scrollHeight: 120,
                scrollInterval: 300
            }
        };
    }

    /*------------------------------------------------------------------
    Methods
    ------------------------------------------------------------------*/

    var
        landingVideoPlayer = null,
        sessionDetailsVideoPlayer = null,
        myScroll,
        boxCloned,
        currentScrollPosition,
        /*
        * Executes when window resizes.
        */
        resizeHandler = function(e) {

            var header = $('header'),
                content = $('#content'),
                _html = $('html'),
                footer = $('footer'),
                win = $(window),
                contentNewHeight = win[0].innerHeight - 193;

            if (!_html.hasClass('landing-video-enlarged') && !_html.hasClass('no-fixed-footer') && !_html.hasClass('needs-iscroll')) {
                content.height(contentNewHeight);
            }

            updateSessionsSliderHeight();

            if (!$('html').hasClass('mejs-fullscreen') && !$('.inner-content').hasClass('landing-edition') && !$('.inner-content').hasClass('session-details') && !$('.inner-content').hasClass('all-sessions')) {
                var player;

                $('video').each(function() {
                    player = $(this)[0].player;

                    if (player) {
                        player.remove();
                    }
                });
            }

            setTimeout(function() {
                var videoHeight = $('.inner-content').outerHeight(true) - $('.session-details-area.bottom').outerHeight(true);
                $('.session-details-area.top').height(videoHeight);
            }, 100);

            if ($('.padded-wrapper').length) {
                $('.padded-wrapper')[0].scrollTop = 0;
            }
        },
        /*
        * Initializes Pride Journal popups.
        */
        initPrideJournalPopups = function() {
            loadPrideJournalPopupsContent();

            $('.has-popup').on('click', function(e) {
                showPopup($(this));
            });
        },
        showPopup = function(item) {
            var popup = $('.pride-journal-popup'),
                edition = item.data('edition'),
                session = item.data('session'),
                downloadLinks = lkApi.prideJournalPopups[edition][session].downloadLinks,
                docFragment = document.createDocumentFragment();

            popup.find('.popup-title').html('Session ' + lkApi.prideJournalPopups[edition][session].idx + ': ' + lkApi.prideJournalPopups[edition][session].title.toUpperCase());
            popup.find('.popup-content').html(lkApi.prideJournalPopups[edition][session].content);

            var anchor = document.createElement('a');

            anchor.href = downloadLinks['link'];
            anchor.innerHTML = downloadLinks['name'];
            anchor.setAttribute('target', '_blank');

            docFragment.appendChild(anchor);

            // Empty links
            popup.find('.popup-links')[0].innerHTML = '';

            // Add newly created anchors
            popup.find('.popup-links')[0].appendChild(docFragment);

            // Show popup.
            popup.fadeIn(400);
        },
        /*
        * Loads Pride Journal homeworks for both KIDS/JUNIOR.
        */
        loadPrideJournalPopupsContent = function() {
            $.getJSON(lkApi.URLS.prideJournalDataUrl, function(response) {
                if (+response.success) {
                    lkApi.prideJournalPopups = response.entity;

                    for (var prop in lkApi.prideJournalPopups) {
                        var i = 0;

                        if (lkApi.prideJournalPopups.hasOwnProperty(prop)) {

                            for (var p in lkApi.prideJournalPopups[prop]) {
                                lkApi.prideJournalPopups[prop][p].idx = i + 1;
                                i++;
                            }
                        }
                    }
                }
            });
        },
        bindRegistrationNextHandler = function() {
            $('.registration-popup .next-btn').on('click', registrationNextBtnClickHandler);
        },
        bindAccountSetupActivateHandler = function() {
            $('.account-setup-popup .next-btn').on('click', accountSetupActivateClickHandler);
        },
        bindWindowResizeHandler = function() {
            $(window).on('resize', _.debounce(resizeHandler, 80));
        },
        initTeacherLoginPopup = function() {
            $('.menu li.has-teacher-login > a').on('click', function(e) {
                $(this).closest('.has-teacher-login').toggleClass('selected');
            });

            $('html').on('click', function(e) {
                var target = $(e.target);

                if (!(target.hasClass('has-teacher-login') || target.closest('.has-teacher-login').length)) {
                    var teacherLogin = $('.has-teacher-login');

                    if (teacherLogin.hasClass('selected')) {
                        teacherLogin.removeClass('selected');
                    }
                }
            });
        },
        initGenerateCertificatePopup = function() {
            $('#generate-certificate-button').on('click', function() {
                var congratulationsPopup = $('.congratulations-popup');

                congratulationsPopup.fadeIn(500, function() {
                    $(this).removeClass('hide');
                });
            });
        },
        initTeacherLoginRegisterLink = function() {
            $('.menu li.has-teacher-login .register').on('click', function(e) {
                var registrationPopup = $('.registration-popup');

                registrationPopup.fadeIn(500, function() {
                    $(this).removeClass('hide');
                });

                $(this).closest('.has-teacher-login').removeClass('selected');
            });
        },
        showAccountSetupPopup = function() {
            var accountSetupPopup = $('.account-setup-popup');

            $('.registration-popup').find('.registration-id-txtbox').val('');

            accountSetupPopup.fadeIn(500, function() {
                $(this).removeClass('hide');
            });
        },
        prepopulateAccountSetupPopup = function() {
            var popup = $('.account-setup-popup'),
                organizationHeader = popup.find('.organization.header'),
                organization = popup.find('.details-row .org'),
                edition = popup.find('.details-row .edition'),
                firstName = popup.find('.first-name'),
                lastName = popup.find('.last-name'),
                email = popup.find('.email');

            firstName.val(lkApi.ACCOUNT_DETAILS.firstName);
            lastName.val(lkApi.ACCOUNT_DETAILS.lastName);
            email.val(lkApi.ACCOUNT_DETAILS.email);
            organizationHeader.html(lkApi.ACCOUNT_DETAILS.organization);
            organization.html(lkApi.ACCOUNT_DETAILS.organization);
            edition.html(lkApi.ACCOUNT_DETAILS.showTitle);
        },
        confirmPasswordBlurHandler = function() {
            $('.confirm-password').on('keyup', function(e) {
                var confirmPasswordValue = $(e.target).val(),
                    passwordValue = $(this).closest('.account-details').find('.password').val(),
                    nextBtn = $(this).closest('.account-setup-popup').find('.next-btn');

                if (passwordValue !== confirmPasswordValue) {
                    nextBtn.prop('disabled', true);
                } else {
                    nextBtn.prop('disabled', false);
                }
            });
        },

        bindClosePopupHandler = function() {
            var resetPopupContent = function(box) {

                // If redirect popup is opened, be sure to clear redirect timeout
                // before closing the popup
                if (box.hasClass('redirect-popup')) {
                    clearTimeout(window.redirectTimeoutID);
                    window.redirectTimeoutID = null;
                }

                box.fadeOut(500, function() {
                    box.addClass('hide');

                    // Reset inputs
                    box.find('.error-message').html('');
                    box.find('input, select, textarea').val('');
                });

                // Redirect to root when account is successfully activated.
                if (box.hasClass('account-activated-popup')) {
                    location.href = location.protocol + '//' + location.host;
                }

                // Destroy player when popup video
                if (box.hasClass('video-popup')) {
                    $('video#additional-material-video').each(function() {
                        player = $(this)[0].player;
                        player.pause(); // for IE
                        player.remove();
                    });
                }

                // Destroy player when popup audio
                if (box.hasClass('audio-popup')) {
                    $('audio#additional-material-audio').each(function() {
                        player = $(this)[0].player;
                        player.pause(); // for IE
                        player.remove();
                    });
                }

                //reset help form
                if (box.hasClass('help-popup')) {
                    $('.success').addClass('hide');
                    $('.all-form').removeClass('hide');
                }
            }
            $('.popup .popup-close-btn').on('click', function(e) {
                resetPopupContent($(this).closest('.popup'));
            });

            $('.popup').on('click', function(e) {
                var target = $(e.target);

                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();

                resetPopupContent($(this));

            });

            $('.popup-body').on('click', function(e) {
                var target = $(e.target);

                if (!target.is('a')) {
                    e.preventDefault();
                }

                e.stopPropagation();
                e.stopImmediatePropagation();
            });
        },
        bindLoginHandler = function() {
            $('.teacher-login-container .login-btn').on('click', loginClickHandler);
            $('.teacher-login-container .login-form input').on('keyup', function(e) {
                var keycode = e.keyCode || e.which;

                if (keycode === 13) {
                    $('.teacher-login-container .login-btn').trigger('click');
                }
            });
        },
        bindLoginForAnonymousUserHandler = function() {
            $('#form-anonymous-login .login-btn').on('click', loginForAnonymousUserClickHandler);
            $('#form-anonymous-login .log-in-popup-left input').on('keyup', function(e) {
                var keycode = e.keyCode || e.which;

                if (keycode === 13) {
                    if ($('#form-anonymous-login').hasClass('forgot-password')) {
                        $('#form-anonymous-login .forgot-password-btn').trigger('click');
                    } else {
                        $('#form-anonymous-login .login-btn').trigger('click');
                    }
                }
            });
        },
        bindGenerateCertificateHandler = function() {
            $('.congratulations-popup .generate-your-certificate-btn').on('click', generateCertificateClickHandler);
        },
        bindLandingPlayVideoBtn = function() {
            $('.landing-edition .play-video-btn').on('click', landingPlayVideoBtnClickHandler);
        },
        bindSaveAccountDetailsBtn = function() {
            $('.save-account-details').on('click', saveAccountDetailsBtnClickHandler);
        },
        initMediaElementPlayer = function(cssSelector, settings) {
            if ($(cssSelector)) {
                $(cssSelector).mediaelementplayer(settings);
            }
        },
        bindLandingVideoCloseBtn = function() {
            $('.landing-edition .custom-video-close-btn').on('click', landingCloseVideoBtnClickHandler);
        },
        initCustomScroll = function(obj) {
            obj.find('.scroll-pane').jScrollPane({
                autoReinitialise: true
            });
        },
        destroyCustomScroll = function(obj) {
            obj.find('.scroll-pane').jScrollPane().data().jsp.destroy();
        },
        /*
        * Handles click on a session item in session listing.
        */
        bindClickOnSessionItemCheckbox = function() {
            $('.box-session .box-checkbox.to-check').on('click', sessionItemCheckboxClickHandler);
        },
        toggleSessionItemExpandedState = function() {
            $('.needs-vertical-carousel .box-session .more').on('click', sessionItemExpandedStateHandler);
        },
        handleBoxSessionOverlayClick = function() {
            $('.body-overlay').on('click', boxSessionClonedLessClickHandler);
        },
        handleHeaderFooterOverlaysClick = function() {
            $('header .header-overlay, footer .footer-overlay, .left-side-overlay').on('click', boxSessionClonedLessClickHandler);
        },
        initSessionsSlider = function() {

            var myVarUp;
            var myVarDown;

            // Init all sessions iScroll
            if($('.inner-content').hasClass('all-sessions')) {
                myScroll = new IScroll('.needs-vertical-carousel', {
                    scrollbars: true,
                    mouseWheel: true,
                    interactiveScrollbars: true,
                    shrinkScrollbars: 'scale',
                    fadeScrollbars: false
                });

                myScroll.on('scrollStart', function() {
                    //destroy tipsy
                    $('.tipsy:last').remove();
                });
            }

            $('.session-arrow-up').off('click').on('click', function(e) {

                if(myScroll.y + lkApi.scrollSettings.scrollInterval >= 0)
                    myScroll.scrollTo(0, 0);
                else
                    myScroll.scrollBy(0, lkApi.scrollSettings.scrollInterval);
            });

            $('.session-arrow-up').off('mousedown').on('mousedown', function(e) {
                myVarUp = setInterval(function() {
                    if(myScroll.y + lkApi.scrollSettings.scrollInterval >= 0) {
                        myScroll.scrollTo(0, 0);

                        clearInterval(myVarUp);
                    }
                    else {
                        myScroll.scrollBy(0, lkApi.scrollSettings.scrollHeight);
                    }
                }, lkApi.scrollSettings.scrollInterval);
            });

            $('.session-arrow-up').off('mouseup').on('mouseup', function(e) {
                clearInterval(myVarUp);
            });

            $('.session-arrow-down').off('click').on('click', function(e) {

                if(myScroll.y - lkApi.scrollSettings.scrollInterval <= myScroll.maxScrollY)
                    myScroll.scrollTo(0, myScroll.maxScrollY);
                else
                    myScroll.scrollBy(0, -lkApi.scrollSettings.scrollInterval);
            });

            $('.session-arrow-down').off('mousedown').on('mousedown', function(e) {
                myVarDown = setInterval(function() {
                    if(myScroll.y - lkApi.scrollSettings.scrollInterval <= myScroll.maxScrollY) {
                        myScroll.scrollTo(0, myScroll.maxScrollY);

                        clearInterval(myVarDown);
                    }
                    else {
                        myScroll.scrollBy(0, -lkApi.scrollSettings.scrollHeight);
                    }
                }, lkApi.scrollSettings.scrollInterval);
            });

            $('.session-arrow-down').off('mouseup').on('mouseup', function(e) {
                clearInterval(myVarDown);
            });

        },
        boxExpandedState = function(box) {

            currentScrollPosition = myScroll.y;

            boxCloned = box.clone(true);

            var cssObj = { width: box.width(), top: box.offset().top, left: box.offset().left, position: 'absolute', zIndex: 2 },
                initialHeight = box.height(),
                finalHeight;

            boxCloned.css(cssObj).appendTo('body').addClass('expanded');

            finalHeight = boxCloned.height();

            $('body').toggleClass('box-session-expanded');

            //initializing new position, jscroll pane, destroy iscroll, hiding iscroll arrows
            if(boxCloned.offset().top - (finalHeight - initialHeight) < $('.needs-vertical-carousel').offset().top) {
                boxCloned.offset({top: $('.needs-vertical-carousel').offset().top});
                myScroll.scrollTo(0, 0);
            }
            else {
                boxCloned.offset({top: boxCloned.offset().top - (finalHeight - initialHeight)});
                myScroll.scrollTo(0, myScroll.y - (finalHeight - initialHeight));
            }

            initCustomScroll(boxCloned);
            myScroll.destroy();
            $('.nav-arrow').hide();

            boxCloned.find('.less').on('click', boxSessionClonedLessClickHandler);

        },
        boxSessionClonedLessClickHandler = function(e) {
            destroyCustomScroll(boxCloned);
            boxCloned.remove();

            myScroll = new IScroll('.needs-vertical-carousel', {
                scrollbars: true,
                mouseWheel: true,
                interactiveScrollbars: true,
                shrinkScrollbars: 'scale',
                fadeScrollbars: false
            });
            myScroll.scrollTo(0, currentScrollPosition);
            $('.nav-arrow').show();

            $('body').toggleClass('box-session-expanded');
        },
        updateSessionsSliderHeight = function() {
            var sessionsSlider = $('.left-side-sessions'),
                contentHeight = $('#content').outerHeight(true),
                sessionsScrollWrapper = $('.sessions-scroll-wrapper'),
                finalHeight;

            if (sessionsSlider.length) {
                finalHeight = contentHeight - parseInt(sessionsSlider.css('padding-top').replace('px', '')) - parseInt(sessionsSlider.css('padding-bottom').replace('px', ''));

                setTimeout(function() {
                    sessionsSlider.height(finalHeight);
                    myScroll.refresh();
                    //sessionsSlider.height(finalHeight);
                    //sessionsSlider.height($(window).height() - 67 - 108 - 150);
                    //sessionsSlider.height($(window).height() - 67 - 108 - 119);

                    $('.generate-certificate-btn').removeClass('do-not-show-me');
                }, 0);
            }
        },
        bindUncheckAllSessionsBtn = function() {
            $('.uncheck-all-sessions').on('click', uncheckAllSessionsBtnClickHandler);
        },
        bindSessionCompleteBtn = function() {
            $('.complete-session-btn').on('click', sessionCompleteBtnClickHandler);
        },
        makeCall = function(settings, successCallback, errorCallback, failCallback) {
            if (!failCallback) {
                failCallback = function(error) {
                    console.log('Ooops, server error!', error)
                };
            }

            $
                .ajax(settings)
                .done(function(data) {
                    var responseId = +data.success;

                    switch (responseId) {
                        case 0: // Error
                            errorCallback(data);

                            break;
                        case 1: // Success
                            successCallback(data);

                            break;
                    }
                })
                .fail(function(error) {
                    failCallback(error);
                });
        },
        resetRatingPopup = function() {
            var ratingPopup = $('.rating-popup'),
                states = ['social-share', 'poor-experience'];

            // By default, remove all states.
            ratingPopup.removeClass(states.join(' '));

            // Reset rating.
            ratingPopup.find('.rate-stars li').removeClass('selected');

            // Reset experience textarea.
            ratingPopup.find('textarea').val('');
        },
        initCustomVideoPopup = function() {
            $('#generate-certificate-button').on('click', function() {
                var congratulationsPopup = $('.congratulations-popup');

                congratulationsPopup.fadeIn(500, function() {
                    $(this).removeClass('hide');
                });
            });
        };

    /*------------------------------------------------------------------
    Event Handling
    ------------------------------------------------------------------*/

    var registrationNextBtnClickHandler = function(e) {
        var ajaxSettings = {
                url: lkApi.URLS.registerUrl + ($('.registration-id-txtbox').val() || lkApi.PROMO_CODE)
            },
            popup = $(e.target).closest('.popup'),
            errorLabel = popup.find('.error-message'),
            successCallback = function(data) {
                if (data && data.entity) {
                    lkApi.ACCOUNT_DETAILS = data.entity;
                }

                popup.fadeOut(500, function() {
                    popup
                        .removeClass('loading error')
                        .addClass('hide');

                    errorLabel.addClass('hide');

                    prepopulateAccountSetupPopup();
                    showAccountSetupPopup();
                });
            },
            errorCallback = function(data) {
                popup
                    .addClass('error')
                    .removeClass('loading');

                errorLabel
                    .html(data.message)
                    .removeClass('hide');
            },
            failCallback = function(error) {
                popup.addClass('error');

                errorLabel
                    .html(error.message)
                    .removeClass('loading');
            };

        popup.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback, failCallback);
    },
    accountSetupActivateClickHandler = function(e) {
        var popup = $(e.target).closest('.popup'),
            errorLabel = popup.find('.error-message'),
            ajaxSettings = {
                url: lkApi.URLS.accountSetupUrl,
                type: 'POST',
                data: {
                    promoCode: lkApi.ACCOUNT_DETAILS.promoCode || popup.find('.next-btn').data('promo-code'),
                    email: popup.find('.email').val(),
                    firstName: popup.find('.account-details .first-name').val(),
                    lastName: popup.find('.account-details .last-name').val(),
                    password: popup.find('.account-details .password').val(),
                    confirmPassword: popup.find('.account-details .confirm-password').val()
                }
            },
            successCallback = function(data) {
                var accountActivatedPopup = $('.account-activated-popup');

                // Hide Account Setup popup
                popup.fadeOut(500, function() {
                    popup.addClass('hide');
                });

                // Show Account Activated popup
                accountActivatedPopup.fadeIn(500, function() {
                    accountActivatedPopup.removeClass('hide');
                });
            },
            errorCallback = function(data) {
                errorLabel
                    .removeClass('hide')
                    .html(data.message);

                popup
                    .removeClass('loading')
                    .addClass('error');
            };

        popup.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    loginClickHandler = function(e) {
        var loginForm = $(e.target).closest('.teacher-login-container'),
            email = loginForm.find('#username'),
            password = loginForm.find('#password'),
            errorLabel = loginForm.find('.error-message'),
            ajaxSettings = {
                url: lkApi.URLS.loginUrl,
                type: 'POST',
                data: {
                    username: email.val(),
                    password: password.val()
                }
            },
            successCallback = function(data) {
                //loginForm.removeClass('loading error');
                $('#form_login').submit();
            },
            errorCallback = function(data) {
                errorLabel.html(data.message);

                loginForm
                    .removeClass('loading')
                    .addClass('error');
            };

        loginForm.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    loginForAnonymousUserClickHandler = function(e) {
        var loginForm = $('#form-anonymous-login'),
            email = loginForm.find('.email'),
            password = loginForm.find('.password'),
            errorLabel = loginForm.find('.error-message'),
            ajaxSettings = {
                url: lkApi.URLS.loginUrl,
                type: 'POST',
                data: {
                    username: email.val(),
                    password: password.val()
                }
            },
            successCallback = function(data) {
                //loginForm.removeClass('loading error');
                $('#form-anonymous-login').submit();
            },
            errorCallback = function(data) {
                errorLabel.removeClass('hide').html(data.message);

                loginForm
                    .removeClass('loading')
                    .addClass('error');
            };

        loginForm.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    landingPlayVideoBtnClickHandler = function(e) {
        var btn = $(e.target),
            videoSelector = btn.data('video-selector'),
            settings = {
                success: function(player, node) {
                    landingVideoPlayer = player;

                    $('#' + node.id + '-mode').html('mode: ' + player.pluginType);

                    setTimeout(function() {
                        player.play();
                    }, 0);
                }
            };

        $('html').addClass('landing-video-enlarged');

        setTimeout(function() {
            $('.landing-video-enlarged .custom-video-player').removeClass('hide');

            initMediaElementPlayer(videoSelector, settings);

            $('.content').css('minHeight', $('.landing-header').outerHeight(true) + $('.landing-text').outerHeight(true) + 50);

            if ($(window).width() > lkApi.showShadowsAfterThisWidth) {
                $('html.landing-video-enlarged .landing-edition > .right-shadow, html.landing-video-enlarged .landing-edition > .left-shadow').addClass('hide-for-good');
                $('.landing-text .right-shadow, .landing-text .left-shadow').addClass('show-for-good');
            }
        }, 1200);
    },
    landingCloseVideoBtnClickHandler = function(e) {
        var player;

        $('video').each(function() {
            if($(this) && $(this)[0] && $(this)[0].player) {
                player = $(this)[0].player;
                player.pause(); // for IE
                player.remove();
            }
        });

        $('html').removeClass('landing-video-enlarged');
        $('.custom-video-player').addClass('hide');
        $('.content').css('minHeight', 0);

        if ($(window).width() > lkApi.showShadowsAfterThisWidth) {
            $('.landing-edition > .right-shadow, .landing-edition > .left-shadow').removeClass('hide-for-good');
            $('.landing-text .right-shadow, .landing-text .left-shadow').removeClass('show-for-good');
        }
    },
    sessionItemCheckboxClickHandler = function(e) {
        var btn = $(e.target),
            sessionBox = $(e.target).closest('.box-session'),
            ratingPopup = $('.rating-popup');

        lkApi.SESSION_BOX_SELECTED = sessionBox;
        lkApi.SESSION_ID = +sessionBox.data('session-id') || 0;

        if (sessionBox.hasClass('checked')) {
            updateSpecificSession(lkApi.SESSION_ID, 'UNCOMPLETED');
        } else {
            resetRatingPopup();

            // Dupe code below...
            // TODO: refactor it
            var comments = '',
                rating = '',
                isUserLoggedIn = $(this).data('user-is-logged-in'),
                ajaxSettings = {
                    url: lkApi.URLS.updateSessionStatus,
                    type: 'POST',
                    data: {
                        session: lkApi.SESSION_ID,
                        status: 'COMPLETED',
                        comments: comments,
                        rating: rating
                    }
                },
                successCallback = function(data) {
                    if (!$('body').hasClass('all-sessions')) {
                        var completeSessionBtn = $('.complete-session-btn');

                        completeSessionBtn.data('previous-rating', rating);
                        completeSessionBtn.data('previous-comments', comments);
                        completeSessionBtn.addClass('checked');
                    } else {
                        lkApi.SESSION_BOX_SELECTED.addClass('checked');
                        //lkApi.SESSION_BOX_SELECTED = null;
                    }
                },
                errorCallback = function(data) {
                    console.log(data, 'Error! session.lock');
                };

            // If user is logged in then make service call
            if (isUserLoggedIn) {
                makeCall(ajaxSettings, successCallback, errorCallback);
            } else { // User is not logged in, we should display log-in-popup
                ratingPopup.addClass('log-in-popup');
            }

            ratingPopup.find('.session-number').html(btn.data('session-number'));
            ratingPopup.find('.session-title').html(btn.data('session-title'));

            ratingPopup
                .removeClass('poor-experience social-share')
                .fadeIn(500, function() {
                    ratingPopup.removeClass('hide');
                });
        }
    },
    sessionItemExpandedStateHandler = function(e) {
        boxExpandedState($(e.target).closest('.box-session'));
    },
    boxSessionOverlayClickHandler = function(e) {
        if(boxCloned.length)
            toggleBoxExpandedState(boxCloned);
    },
    headerFooterOverlaysClickHandler = function(e) {
        if(boxCloned.length)
            toggleBoxExpandedState(boxCloned);
    },
    generateCertificateClickHandler = function(e) {
        var generateCertificatePopup = $(e.target).closest('.congratulations-popup'),
            errorMessage = generateCertificatePopup.find('.error-message'),
            ajaxSettings = {
                url: lkApi.URLS.generateCertificateUrl,
                type: 'POST',
                data: {
                    school: generateCertificatePopup.find('#certificate-school-txtbox').val(),
                    classPride: generateCertificatePopup.find('#certificate-class-txtbox').val(),
                    year: generateCertificatePopup.find('#certificate-year-txtbox').val()
                }
            },
            successCallback = function(data) {
                $('#generate_certificate_form').submit();

                generateCertificatePopup
                    .fadeOut(500, function() {
                        generateCertificatePopup
                            .removeClass('loading')
                            .addClass('hide');
                    });
            },
            errorCallback = function(data) {
                errorMessage
                    .removeClass('hide')
                    .html(data.message);

                generateCertificatePopup
                    .removeClass('loading')
                    .addClass('error');
            };

        generateCertificatePopup.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    saveAccountDetailsBtnClickHandler = function(e) {
        var popup = $(e.target).closest('.account-information-section'),
            errorLabel = popup.find('.error-message'),
            btn = popup.find('.save-account-details'),
            ajaxSettings = {
                url: lkApi.URLS.accountUpdateUrl,
                type: 'POST',
                data: {
                    firstName: popup.find('#first-name-txtbox').val(),
                    lastName: popup.find('#last-name-txtbox').val(),
                    email: popup.find('#email-txtbox').val(),
                    oldPassword: popup.find('#old-pass-txtbox').val(),
                    password: popup.find('#new-pass-txtbox').val(),
                    confirmPassword: popup.find('#confirm-pass-txtbox').val()
                }
            },
            successCallback = function(data) {
                btn.prop('disabled', false);

                errorLabel
                    .removeClass('hide')
                    .html('Account successfully updated!');
            },
            errorCallback = function(data) {
                btn.prop('disabled', false);

                errorLabel
                    .removeClass('hide')
                    .html(data.message);
            };

        btn.prop('disabled', true);

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    uncheckAllSessionsBtnClickHandler = function(e) {
        var ajaxSettings = {
                url: lkApi.URLS.updateSessionStatus,
                type: 'POST',
                data: {
                    session: '',
                    status: 'UNCOMPLETED'
                }
            },
            successCallback = function(data) {
                uncheckAllSessionBoxes();
            },
            errorCallback = function(data) {
                console.log(data, 'Error: errorCallback for uncheckAllSessionsBtnClickHandler');
            };

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    sessionCompleteBtnClickHandler = function(e) {
        var btn = $(this),
            ratingPopup = $('.rating-popup'),
            isSessionCompleted = btn.hasClass('checked');

        lkApi.SESSION_ID = +btn.data('session-id');

        ratingPopup.find('.session-number').html(btn.data('session-number'));
        ratingPopup.find('.session-title').html(btn.data('session-title'));
        ratingPopup.removeClass('poor-experience social-share');

        if (!isSessionCompleted) {
            // Dupe code below...
            // TODO: refactor it
            var comments = '',
                rating = '',
                isUserLoggedIn = $(this).data('user-is-logged-in'),
                ajaxSettings = {
                    url: lkApi.URLS.updateSessionStatus,
                    type: 'POST',
                    data: {
                        session: lkApi.SESSION_ID,
                        status: 'COMPLETED',
                        comments: comments,
                        rating: rating
                    }
                },
                successCallback = function(data) {
                    if (!$('body').hasClass('all-sessions')) {
                        var completeSessionBtn = $('.complete-session-btn');

                        completeSessionBtn.data('previous-rating', rating);
                        completeSessionBtn.data('previous-comments', comments);
                        completeSessionBtn.addClass('checked');
                    } else {
                        lkApi.SESSION_BOX_SELECTED.addClass('checked');
                    }
                },
                errorCallback = function(data) {
                    console.log(data, 'Error! session.lock');
                };

            // If user is logged in then make service call
            if (isUserLoggedIn) {
                makeCall(ajaxSettings, successCallback, errorCallback);
            } else { // User is not logged in, we should display log-in-popup
                ratingPopup.addClass('log-in-popup');
            }

            ratingPopup.fadeIn(500, function() {
                ratingPopup.removeClass('hide');
            });
        // If session is already COMPLETED
        } else {
            var previousRating = btn.data('previous-rating'),
                previousComments = btn.data('previous-comments');

            updatePreviousRatingForPopup(ratingPopup, previousRating, previousComments);

            ratingPopup.fadeIn(500, function() {
                ratingPopup.removeClass('hide');
            });
        }
    },
    updatePreviousRatingForPopup = function(ratingPopup, rating, comments) {
        if (ratingPopup) {
            if (rating && rating >= 1) {
                var star = $(ratingPopup.find('.rate-stars li').get(rating - 1));
                star.find('a').trigger('click');
            }

            if (comments) {
                ratingPopup.find('textarea').val(comments);
            }
        }
    },
    updateSpecificSession = function(sessionId, sessionStatus) {
        var ajaxSettings = {
                url: lkApi.URLS.updateSessionStatus,
                type: 'POST',
                data: {
                    session: sessionId,
                    status: sessionStatus
                }
            },
            successCallback = function(data) {
                uncheckSpecificSession(lkApi.SESSION_BOX_SELECTED);
            },
            errorCallback = function(data) {
                console.log(data, 'Error: errorCallback for updateSessionStatus');
            };

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    uncheckAllSessionBoxes = function() {
        $('.box-session').removeClass('checked');
    },
    uncheckSpecificSession = function(sessionBox) {
        sessionBox.removeClass('checked');
    },
    initRatingMechanism = function() {
        var popup = $('.rating-popup'),
            ratingIndex = 0;

        // Mouseover stars animation.
        popup.find('.rate-stars li').on('mouseover', function(e) {
            var index = $(this).index();

            ratingIndex = index + 1;

            //$('.rating-popup .rate-stars li').removeClass('selected');
            //$('.rating-popup .rate-stars li:lt(' + (index + 1) + ')').addClass('selected');
        });

        // When a star is clicked...
        popup.find('.rate-stars li > a').on('click', function(e) {
            var index = $(this).closest('li').index() + 1,
                states = ['social-share', 'poor-experience'];

            $('.rating-popup .rate-stars li').removeClass('selected');
            $('.rating-popup .rate-stars li:lt(' + index + ')').addClass('selected');

            // By default, remove all states.
            popup.removeClass(states.join(' '));

            // Reset textarea content.
            popup.find('textarea').val('');

            // If 1 or 2 stars are selected, then show poor experience textbox
            if (index < 3) {
                popup.addClass('poor-experience');
            } else if (index > 3) {
                popup.addClass('social-share');
            }
        });

        $('.rating-popup .submit-btn, .rating-popup .skip').on('click', function(e) {
            var comments = (popup.hasClass('poor-experience') && !$(e.target).hasClass('skip')) ? popup.find('textarea').val() : '',
                rating = !$(e.target).hasClass('skip') ? ratingIndex : '',
                isUserLoggedIn = $(this).data('user-is-logged-in'),
                ajaxSettings = {
                    url: lkApi.URLS.updateSessionStatus,
                    type: 'POST',
                    data: {
                        session: lkApi.SESSION_ID,
                        status: 'COMPLETED',
                        comments: comments,
                        rating: rating
                    }
                },
                successCallback = function(data) {
                    if (!$('body').hasClass('all-sessions')) {
                        var completeSessionBtn = $('.complete-session-btn');

                        completeSessionBtn.data('previous-rating', rating);
                        completeSessionBtn.data('previous-comments', comments);
                        completeSessionBtn.addClass('checked');
                    } else {
                        lkApi.SESSION_BOX_SELECTED.addClass('checked');
                        lkApi.SESSION_BOX_SELECTED = null;
                    }

                    popup.fadeOut(500, function() {
                        popup.addClass('hide');
                    });
                },
                errorCallback = function(data) {
                    console.log(data, 'Error! session.unlock');
                };

            // If user is logged in then make service call
            if (isUserLoggedIn) {
                makeCall(ajaxSettings, successCallback, errorCallback);
            } else { // User is not logged in, we should display log-in-popup
                popup.addClass('log-in-popup');
            }
        });
    },
    sessionDetailsCompleteBtnClickHandler = function(e) {
        var btn = $(this),
            ratingPopup = $('.rating-popup');

        resetRatingPopup();

        if (btn.hasClass('checked')) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();

            return false;
        }

        lkApi.SESSION_ID = +btn.data('session-id') || -1;

        ratingPopup
            .removeClass('poor-experience social-share')
            .fadeIn(500, function() {
                ratingPopup.removeClass('hide');
            });
    },
    bindVerticalScrollNavigation = function() {

        var myVarUp;
        var myVarDown;

        if($('html').hasClass('needs-iscroll')) {
            myScroll = new IScroll('html.needs-iscroll .padded-wrapper', {
                scrollbars: true,
                mouseWheel: true,
                interactiveScrollbars: true,
                shrinkScrollbars: 'scale',
                fadeScrollbars: false
            });

            window.test = myScroll;
        }

        $('.inner-content .arrow-up').off('click').on('click', function(e) {

            if(myScroll.y + lkApi.scrollSettings.scrollInterval >= 0)
                myScroll.scrollTo(0, 0);
            else
                myScroll.scrollBy(0, lkApi.scrollSettings.scrollInterval);

        });

        $('.inner-content .arrow-up').off('mousedown').on('mousedown', function(e) {
            myVarUp = setInterval(function() {
                if(myScroll.y + lkApi.scrollSettings.scrollInterval >= 0) {
                    myScroll.scrollTo(0, 0);

                    clearInterval(myVarUp);
                }
                else {
                    myScroll.scrollBy(0, lkApi.scrollSettings.scrollHeight);
                }
            }, lkApi.scrollSettings.scrollInterval);
        });

        $('.inner-content .arrow-up').off('mouseup').on('mouseup', function(e) {
            clearInterval(myVarUp);
        });

        $('.inner-content .arrow-down').off('click').on('click', function(e) {

            if(myScroll.y - lkApi.scrollSettings.scrollInterval <= myScroll.maxScrollY)
                myScroll.scrollTo(0, myScroll.maxScrollY);
            else
                myScroll.scrollBy(0, -lkApi.scrollSettings.scrollInterval);

        });

        $('.inner-content .arrow-down').off('mousedown').on('mousedown', function(e) {
            myVarDown = setInterval(function() {
                if(myScroll.y - lkApi.scrollSettings.scrollInterval <= myScroll.maxScrollY) {
                    myScroll.scrollTo(0, myScroll.maxScrollY);

                    clearInterval(myVarDown);
                }
                else {
                    myScroll.scrollBy(0, -lkApi.scrollSettings.scrollHeight);
                }
            }, lkApi.scrollSettings.scrollInterval);
        });

        $('.inner-content .arrow-down').off('mouseup').on('mouseup', function(e) {
            clearInterval(myVarDown);
        });

    },
    bindReadMoreSectionBtn = function() {
        $('.read-more-section-btn').on('click', function(e) {
            $(this).closest('.read-more-section-item').toggleClass('expanded');

            setTimeout(function () {
                myScroll.refresh();
            }, 300);
        });
    },
    bindBuyMtiLicenseBtn = function() {
        /*var redirectPopup = $('.redirect-popup');

        $('.redirect-go-back-btn').on('click', function(e) {
            clearTimeout(window.redirectTimeoutID);
            window.redirectTimeoutID = null;

            redirectPopup.fadeOut(500, function() {
                redirectPopup.addClass('hide');
            });
        });*/

        $('.buy-mti-redirect').on('click', function(e) {
            e.preventDefault();
            /*redirectPopup.fadeIn(500, function() {
                redirectPopup.removeClass('hide');

                var dataEdition = $(e.target).data('edition');

                if (!dataEdition) {
                    // We came here from kids...
                    if (location.href.indexOf('kids') > 0) {
                        dataEdition = 'kids';
                    // We came here from junior...
                    } else if (location.href.indexOf('junior') > 0) {
                        dataEdition = 'jr';
                    } else {
                        // It should never get here :D
                        // but if it does, we redirect to kids just to avoid any 404 errors...
                        dataEdition = 'kids';
                    }
                }

                // Redirect to specific buy URL after lkApi.REDIRECT_URLS.timeout milliseconds,
                // giving user time to cancel the redirect.
                window.redirectTimeoutID = setTimeout(function() {
                    window.location.href = lkApi.REDIRECT_URLS[dataEdition];
                }, lkApi.REDIRECT_URLS.timeout);
            });*/
            // var dataUrl = $(e.target).data('url');
            // window.open(dataUrl, 'MTIPopup', "height=1000,width=800,scrollbars=yes");
        });
    },
    bindReadMoreGettingStartedBtn = function() {
        $('.getting-started-more-btn').on('click', function(e) {
            var edition = $(this).data('edition'),
                classToToggle = edition ? 'expanded ' + edition : 'expanded single-item',
                item = $(this).closest('.getting-started-item');

            // Make collapsed.
            if (item.hasClass('expanded')) {

                if ((item.hasClass('jr') && edition === 'kids') || (item.hasClass('kids') && edition === 'jr')) {
                    item.removeClass('jr kids');
                    item.addClass(classToToggle);
                } else {
                    item.removeClass(classToToggle);
                }
            // Make expanded.
            } else {
                item.addClass(classToToggle);
            }

            setTimeout(function () {
                myScroll.refresh();
            }, 300);
        });
    },
    bindLockedSessionBtn = function() {
        $('.locked-session').on('click', function(e) {
            var ratingPopup = $('.rating-popup'),
                states = ['social-share', 'poor-experience', 'log-in-popup'];

            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            ratingPopup.removeClass(states.join(' ')).addClass('log-in-popup');
            ratingPopup.fadeIn(500, function() {
                ratingPopup.removeClass('hide');
            });
        });
    },
    backButtonNoCacheFFFix = function() {
        // http://stackoverflow.com/questions/15791148/startup-javascript-code-is-not-executed-when-using-the-firefox-back-button
        $(window).unload(function () { $(window).unbind('unload'); });
    },
    initForgotPasswordPopup = function() {
        var forgotPasswordForm = $('.forgot-password-form'),
            popup = $('.teacher-login-container');

        $('.forgot-password-link').on('click', function(e) {
            popup
                .removeClass('error loading')
                .addClass('forgot-password');
        });

        $('.teacher-login-container .forgot-password-form input').on('keyup', function(e) {
            var keycode = e.keyCode || e.which;

            if (keycode === 13) {
                $('.teacher-login-container .forgot-password-btn').trigger('click');
            }
        });

        forgotPasswordForm.find('.forgot-password-btn').on('click', function(e) {
            var msgLabel = popup.find('.error-message'),
                ajaxSettings = {
                    url: lkApi.URLS.resetPasswordUrl,
                    type: 'POST',
                    data: {
                        email: lkApi.ACCOUNT_DETAILS.email || forgotPasswordForm.find('#forgot-email').val()
                    }
                },
                successCallback = function(data) {
                    popup
                        .removeClass('loading')
                        .addClass('info');

                    msgLabel
                            .removeClass('hide')
                            .html('Please check your email.');
                },
                errorCallback = function(data) {
                    popup
                        .removeClass('loading')
                        .addClass('error');

                    msgLabel
                        .removeClass('hide')
                        .html(data.message);
                };

            popup.addClass('loading');

            makeCall(ajaxSettings, successCallback, errorCallback);
        });
    },
    bindSessionDetailsPlayVideoBtn = function() {
        $('.session-details-video-mask .play-video-btn').on('click', function(e) {
            var btn = $(e.target),
                videoSelector = btn.data('video-selector'),
                videoH = $(window).height() / 1.6,
                settings = {
                    videoHeight: videoH,
                    success: function(player, node) {
                        sessionDetailsVideoPlayer = player;

                        $('#' + node.id + '-mode').html('mode: ' + player.pluginType);

                        setTimeout(function() {
                            player.play();
                        }, 0);
                    }
                };

            $('html').addClass('landing-video-enlarged');

            $('.session-details-area.top').data('old-height', $('.session-details-area.top').outerHeight(true));
            $('.session-details-area.top').height(videoH);

            setTimeout(function() {
                $('.landing-video-enlarged .custom-video-player').removeClass('hide');

                $('.content').css('minHeight', videoH + $('.session-details-area.bottom').outerHeight(true));

                initMediaElementPlayer(videoSelector, settings);
            }, 1200);
        });
    },
    bindSessionDetailsCloseVideoBtn = function() {
        $('.session-details .custom-video-close-btn').on('click', function(e) {
            var player;

            $('video').each(function() {
                if($(this) && $(this)[0] && $(this)[0].player) {
                    player = $(this)[0].player;
                    player.pause(); // for IE
                    player.remove();
                }
            });


            $('html').removeClass('landing-video-enlarged');
            $('.custom-video-player').addClass('hide');
            $('.content').css('minHeight', 0);

            setTimeout(function() {
                $('.session-details-area.top').height($('.session-details-area.top').data('old-height'));
            }, 0);

            /*if ($(window).width() > lkApi.showShadowsAfterThisWidth) {
                $('.landing-edition > .right-shadow, .landing-edition > .left-shadow').removeClass('hide-for-good');
                $('.landing-text .right-shadow, .landing-text .left-shadow').removeClass('show-for-good');
            }*/
        });
    },
    bindForgotPasswordAnonymousHandler = function() {
        var parentForm = $('#form-anonymous-login'),
            msgLabel = parentForm.find('.forgot-password-container .error-message');

        $('.log-in-popup-view-all-sessions .forgot-pass').on('click', function(e) {
            // Reset message label.
            msgLabel.html('');

            parentForm.addClass('forgot-password');
        });

        $('.forgot-password-container .forgot-password-btn').on('click', function(e) {
            var ajaxSettings = {
                    url: lkApi.URLS.resetPasswordUrl,
                    type: 'POST',
                    data: {
                        email: parentForm.find('#email-to-forgot-password').val()
                    }
                },
                successCallback = function(data) {
                    parentForm
                        .removeClass('loading-forgot-password')
                        .addClass('info');

                    msgLabel
                            .removeClass('hide')
                            .html('Please check your email.');
                },
                errorCallback = function(data) {
                    parentForm
                        .removeClass('loading-forgot-password')
                        .addClass('error');

                    msgLabel
                        .removeClass('hide')
                        .html(data.message);
                };

            parentForm.addClass('loading-forgot-password');

            makeCall(ajaxSettings, successCallback, errorCallback);
        });
    },
    /*
    * Yeah, I know, that's a short name, right? :-)
    * Below smaller resolutions (under 1100px) we're truncating asset filenames, constrainting to max N chars
    * On hover, we show up a popup with the full name.
    * Also, gets called on resize.
    */
    handleTruncatedFilenames = function(nChars) {
        var additionalMaterialFilenames = $('li.additional-material > a');

        $.each(additionalMaterialFilenames, function(i, el) {
            var additionalMaterial = $(el).find('.add-mat.truncated-filename'),
                oldFilename = additionalMaterial.attr('title'),
                newFilename = '';

            newFilename = oldFilename.substr(0, nChars - 3) + '...';
            additionalMaterial.find('label').html(newFilename);

            additionalMaterial.tipsy({ delayIn: 200, delayOut: 0, gravity: $.fn.tipsy.autoNS });
        });
    },
    applyAnimateEffectTo = function(selector, animation, resetTimeout) {
        $(selector).on('mouseover', function(e) {
            $(this).addClass(animation);

            setTimeout(function() {
                $(selector).removeClass(animation);
            }, resetTimeout);
        });
    },
    bindVideoSessionBtn = function() {
        $('.open-video-popup').on('click', function(e) {
            var btn = $(this),
                videoPopup = $('.video-popup');

            videoPopup.fadeIn(500, function() {
                videoPopup.removeClass('hide');

                videoPopup.find('.video-audio-popup-title').html(btn.data('video-title'));

                var videoSelector = '#additional-material-video',
                    settings = {
                        success: function(player, node) {

                            $('#' + node.id + '-mode').html('mode: ' + player.pluginType);

                            setTimeout(function() {
                                player.setSrc([
                                    { src: btn.data('video-src'), type: 'video/mp4'}
                                ]);
                                player.play();
                            }, 0);
                        }
                    };

                initMediaElementPlayer(videoSelector, settings);

            });
        });
    },
    bindAudioSessionBtn = function() {
        $('.open-audio-popup').on('click', function(e) {
            var btn = $(this),
                audioPopup = $('.audio-popup');

            audioPopup.fadeIn(500, function() {
                audioPopup.removeClass('hide');

                audioPopup.find('.video-audio-popup-title').html(btn.data('audio-title'));

                var audioSelector = '#additional-material-audio',
                    settings = {
                        success: function(player, node) {

                            $('#' + node.id + '-mode').html('mode: ' + player.pluginType);

                            setTimeout(function() {
                                player.setSrc([
                                    { src: btn.data('audio-src'), type: 'audio/mp3'}
                                ]);
                                player.play();
                            }, 0);
                        }
                    };

                initMediaElementPlayer(audioSelector, settings);

            });
        });
    },
    handleSkillsIconTip = function() {
        var topListImages = $('ul.top-list-session > li');
        var topListImagesDetails = $('div.has-special-padding > div.float-left');

        $.each(topListImages, function(i, el) {
            var skillIcon = $(el).find('img');

            skillIcon.tipsy({ delayIn: 200, delayOut: 0, gravity: 's', offset: -5 });
        });

        $.each(topListImagesDetails, function(i, el) {
            var skillIconDetails = $(el).find('img');

            skillIconDetails.tipsy({ delayIn: 200, delayOut: 0, gravity: 's', offset: -5 });
        });
    },
    bindHelpFooterBtn = function() {
        $('.open-help-popup').on('click', function(e) {
            var btn = $(this),
                helpPopup = $('.help-popup');

            helpPopup.fadeIn(500, function() {
                helpPopup.removeClass('hide');
            });
        });
    },
    bindHelpSubmitBtn = function() {
        $('.help-popup .painted-button').on('click', submitHelpClickHandler);
        $('.help-popup .painted-button textarea').on('keyup', function(e) {
            var keycode = e.keyCode || e.which;

            if(keycode === 13) {
                $('.help-popup .painted-button').trigger('click');
            }
        });
    },
    submitHelpClickHandler = function(e) {
        var helpForm = $(e.target).closest('.help-popup'),
            email = helpForm.find('#email_faq'),
            name = helpForm.find('#name_faq'),
            phone = helpForm.find('#phone_faq'),
            reason = helpForm.find('#subject_faq'),
            message = helpForm.find('#message_faq'),
            errorLabel = helpForm.find('.error-message'),
            successMessage = helpForm.find('.success'),
            allForm = helpForm.find('.all-form'),
            ajaxSettings = {
                url: lkApi.URLS.submitHelpUrl,
                type: 'POST',
                data: {
                    email: email.val(),
                    name: name.val(),
                    phone: phone.val(),
                    reason: reason.val(),
                    message: message.val()
                }
            },
            successCallback = function(data) {
                helpForm
                    .removeClass('loading error')
                    .addClass('success-message');

                errorLabel
                    .html('')
                    .addClass('hide');

                successMessage.removeClass('hide');
                allForm.addClass('hide');
            },
            errorCallback = function(data) {
                errorLabel
                    .html(data.message)
                    .removeClass('hide');
                helpForm
                    .removeClass('loading')
                    .addClass('error');
            };

        helpForm.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    bindScholarshipSubmitBtn = function() {
        $('.scholarship-form .painted-button').on('click', submitScholarshipClickHandler);
        $('.scholarship-form .painted-button textarea, .scholarship-form .painted-button input').on('keyup', function(e) {
            var keycode = e.keyCode || e.which;

            if(keycode === 13) {
                $('.scholarship-form .painted-button').trigger('click');
            }
        });

        var scholarshipForm = $('form#scholarship'),
            scholarshipError = scholarshipForm.find('#errorMessage');

        if(scholarshipError.length) {
            window.errorLabelTop = scholarshipError.offset().top - 100;
        }
    },
    submitScholarshipClickHandler = function(e) {
        var scholarshipForm = $('form#scholarship'),
            errorLabel = scholarshipForm.find('.error-message'),
            successMessage = scholarshipForm.find('.success'),
            allForm = scholarshipForm.find('.all-form'),
            ajaxSettings = {
                url: lkApi.URLS.submitScholarshipUrl,
                type: 'POST',
                data: {
                    allData: scholarshipForm.serialize()
                }
            },
            successCallback = function(data) {
                scholarshipForm
                    .removeClass('loading error')
                    .addClass('success-message');

                errorLabel
                    .html('')
                    .addClass('hide');

                successMessage.removeClass('hide');
                allForm.addClass('hide');
            },
            errorCallback = function(data) {

                errorLabel
                    .html(data.message)
                    .removeClass('hide');
                scholarshipForm
                    .removeClass('loading')
                    .addClass('error');

                myScroll.scrollTo(0, -window.errorLabelTop);
            };

        scholarshipForm.addClass('loading');

        makeCall(ajaxSettings, successCallback, errorCallback);
    },
    handleInfoRegisterIconTip = function() {

        var skillIcon = $('.registration-popup .info-icon')

        skillIcon.tipsy({ delayIn: 200, html: true, delayOut: 0, gravity: 's', offset: -5 });

    },
    bindCloseForgotPasswordHandler = function() {

        $('.forgot-password-form .popup-close-btn').on('click', function(e) {
            $('#forgot-email').val('');
            $('.error-message').html('');
            $('.teacher-login-container').removeClass('forgot-password');
        });
    };

    /*------------------------------------------------------------------
    Main Logic starts here, dear programmer :)
    ------------------------------------------------------------------*/

    setTimeout(function() {
        resizeHandler();
    }, 300);

    // Uncomment line below when on live!!!
    //initGA();
    //applyAnimateEffectTo('.logo img', 'animated tada', 1000);
    //applyAnimateEffectTo('header .jr-logo img', 'animated tada', 1000);
    handleTruncatedFilenames(10);
    initSessionsSlider();
    initPrideJournalPopups();
    bindWindowResizeHandler();
    bindClosePopupHandler();
    bindRegistrationNextHandler();
    bindAccountSetupActivateHandler();
    bindLoginHandler();
    bindLoginForAnonymousUserHandler();
    bindGenerateCertificateHandler();
    initTeacherLoginPopup();
    initGenerateCertificatePopup();
    initForgotPasswordPopup();
    initTeacherLoginRegisterLink();
    confirmPasswordBlurHandler();
    bindLandingPlayVideoBtn();
    bindLandingVideoCloseBtn();
    bindClickOnSessionItemCheckbox();
    bindSaveAccountDetailsBtn();
    bindUncheckAllSessionsBtn();
    bindSessionCompleteBtn();
    toggleSessionItemExpandedState();
    handleBoxSessionOverlayClick();
    handleHeaderFooterOverlaysClick();
    initRatingMechanism();
    bindVerticalScrollNavigation();
    bindReadMoreSectionBtn();
    bindReadMoreGettingStartedBtn();
    bindBuyMtiLicenseBtn();
    bindLockedSessionBtn();
    backButtonNoCacheFFFix();
    bindSessionDetailsPlayVideoBtn();
    bindSessionDetailsCloseVideoBtn();
    bindForgotPasswordAnonymousHandler();
    bindVideoSessionBtn();
    bindAudioSessionBtn();
    handleSkillsIconTip();
    bindHelpFooterBtn();
    bindHelpSubmitBtn();
    bindScholarshipSubmitBtn();
    handleInfoRegisterIconTip();
    bindCloseForgotPasswordHandler();
});
